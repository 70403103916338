<template>
  <div>
    <b-row v-if="errors.length > 0 || successes.length > 0 ">
        <b-col cols="12">
            <b-alert :show="successes.length > 0" v-for="(item, index) in successes" :key ="index" variant="success" dismissible>{{item.message}}</b-alert>
            <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key ="index" variant="danger" dismissible>{{item.message}}</b-alert>
        </b-col>
    </b-row>
    
        <div v-if="state === 'loading'">
            <b-row align-h="center" align-v="center" class="full-row" >
                <b-spinner  style="width: 3rem; height: 3rem;" type="grow" label="Loading..."></b-spinner>
            </b-row>
        </div>
        <div v-if="state === 'show'">

    <b-row v-if="deviceSelected !== null">
        <b-col cols="12" class="mt-1">
            <b-card class="card-custom-padding card-with-loader tertiary-background text-center" no-body>
              <b-card-body>
            <p v-if="deviceSelected !== null">{{deviceSelected.untSerial}} - {{deviceSelected.untName}}</p>
            <p v-if="deviceSelected === null">No device selected</p>
              </b-card-body>
            <b-card-footer>
    <b-progress :value="loadValue" :max="6000" class="" variant="light-gray"></b-progress>
            </b-card-footer>
            </b-card>
        </b-col>
    </b-row>
    <b-row no-gutters align-h="end" class="pl-3 pr-3 mt-0 ">
      <span class="top-section-info mb-1">Last Active: {{lastEventDate !== null ? lastEventDate : 'No Data Found' | shortDateTimeFilter}}</span>
    </b-row>
    <b-row v-for="(item, index) in devices" :key="index" class="mt-0">
      <b-col cols="12" :class="index === 0 ? 'mt-0' : 'mt-2'">
        <b-card class="card-custom-padding text-right" 
        :class="item.display === true ? item.type === 'Input' ? 'primary-background' : 'secondary-background' : 'gray-background'">
            <b-row align-h="between" align-v="center" class="mt-0">
              <div>
            <img v-if="item.deviceType === 'Analog'" class="icon-img mr-1" src="@/assets/cog-solid.svg" alt="" />
            <img v-if="item.deviceType === 'Digital'" class="icon-img mr-1" src="@/assets/bandcamp-brands.svg" alt="" />
            <img v-if="item.deviceType === 'Virtual'" class="icon-img mr-1" src="@/assets/cogs-solid.svg" alt="" />
            <img v-if="item.deviceType === 'Relay'" class="icon-img mr-1" src="@/assets/sliders-h-solid.svg" alt="" />
            <img v-if="item.deviceType === 'Procedure'" class="icon-img mr-1" src="@/assets/wrench-solid.svg" alt="" />
            <span class="small-text">{{item.deviceType}}</span>
              </div>
              {{item.description}}
            </b-row>
          <!-- <p class="bold">
            <img class="icon-img mx-auto" src="@/assets/cog-solid.svg" alt="">
            {{item.description}}
          </p> -->
          <h4>{{item.reading}} {{item.unitOfMeasurements !== 'Undefined' ? item.unitOfMeasurements :  ''}}</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row></b-row>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
  },
  data:() => ({
    state: 'loading',
    devices: [],
    deviceSelected: null,
    errors: [],
    successes: [],
    lastEventDate: null,
    loadTimer: 0,
    loadValue: 0,
    maxValue: 6000
  }),
  created(){
    if (localStorage.getItem('selectedDevice') !== null) {
        this.deviceSelected = {
            untId: localStorage.getItem('selectedDevice'),
            untName: localStorage.getItem('selectedDeviceName'),
            untSerial: localStorage.getItem('selectedDeviceSerial')
        }
    }
        this.refreshList()
      
    this.startTimer();
    
            this.loadValue = 0
            this.loadTimer = 0
  },
  methods: {
    ...mapActions(['getUnitList']),
    startTimer() {
      let vm = this;
      let loadTimer = setInterval(function() {
        vm.loadValue += 6;
        if (vm.loadValue >= 6000) {
        vm.refreshList()
        clearInterval(loadTimer)}
      }, 100);
    },
        refreshList(){
            this.errors = []
            this.loadValue = 0
            this.loadTimer = 0
            if (localStorage.getItem('selectedDevice') !== null) {
              this.getUnitList()
              .then((response) => {
                this.state = 'show'
                this.startTimer()
                this.lastEventDate = response.data.key
                this.devices = response.data.value
              })
              .catch(() => {
                this.state = 'show'
                this.errors.push({message: 'Could not retrieve list of available Inputs and Outputs'})
              })
            } else {
              this.state = 'show'
              this.successes.push({message: 'Please select a device from the menu.'})
            }
        },
        
        cancelAutoUpdate () {
            clearInterval(this.loadTimer);
        }
  },
  beforeDestroy(){
    this.cancelAutoUpdate();
  }
}
</script>
